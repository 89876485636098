<template>
  <div>
    <div class="float-right">
      <v-btn
        rounded
        color="primary"
        dark
        class="mt-3 mr-5"
        v-on:click="openForm"
      >
        {{ lang.title.createButton }}
      </v-btn>
    </div>
    <v-divider class="mt-15"></v-divider>
    <v-data-table
      :headers="headers"
      :items="list"
      class="elevation-1 mt-5"
    >
      <template v-slot:item.courseTitle="item">
        {{ item.item.course.title }}
      </template>
      <template v-slot:item.amount_money="item">
        <span v-if="item.item.type === 1">
          {{ formatNumber(item.item.amount_money) }} đ
        </span>
        <span v-else> {{ item.item.amount_money }} % </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn class="ma-2" outlined fab x-small color="teal">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          class="ma-2"
          outlined
          fab
          x-small
          color="teal"
          v-on:click="editDiscountCode(item.id)"
        >
          <v-icon>mdi-lead-pencil</v-icon>
        </v-btn>
        <v-btn
          class="ma-2"
          outlined
          fab
          x-small
          color="red"
          v-on:click="deleteDiscountCode(item.id, item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  GET_LIST_DISCOUNT_CODE,
  DELETE_DISCOUNT_CODE
} from "@/store/discountCodes.module";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  name: "list",
  data() {
    return {
      lang: {
        title: {
          createButton: "Tạo mã khuyến mại"
        }
      },
      headers: [
        {
          text: "Mã",
          align: "start",
          sortable: false,
          value: "discount_code"
        },
        { text: "Mức giảm", value: "amount_money" },
        { text: "Mô tả", value: "description", width: "200" },
        { text: "Mô tả khóa học", value: "courseTitle", width: "200" },
        { text: "Đã dùng", value: "number_of_used" },
        { text: "Ngày bắt đầu", value: "start_time" },
        { text: "Ngày kết thúc", value: "end_time" },
        { text: "", value: "action" }
      ],
      list: []
    };
  },
  mounted() {
    this.getListDiscountCode();
  },
  computed: {
    ...mapGetters({
      DiscountCodes: "getListDiscountCode"
    })
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mã khuyến mãi", titledad:'Mã khuyến mãi' }]);
  },
  watch: {
    DiscountCodes: function(val) {
      for (var i = val.length-1; i >= 0; i--){
        this.list.push(val[i]);
      }
    }
  },
  methods: {
    getListDiscountCode() {
      this.$store.dispatch(GET_LIST_DISCOUNT_CODE, "");
    },
    openForm() {
      this.$router.push("/promotion/create");
    },
    editDiscountCode(id) {
      this.$router.push({
        path: "/promotion/update/" + id
      });
    },
    async deleteDiscountCode(id, item) {
      if (window.confirm("Xóa code khuyến mãi!?")) {
        await this.$store.dispatch(DELETE_DISCOUNT_CODE, id);
        const index = this.list.indexOf(item);
        this.list.splice(index, 1);
      }
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>

<style scoped></style>
